<template lang="pug">
div(style="min-height: 70vh")
  v-card(min-height="500")
    div.pt-4.px-2.mx-auto.text-center.font-weight-light.display-1.text-uppercase {{ subject.name }}
    v-divider
    paginate(
    :limit="limit",
    collection="books",
    order="title",
    field="subject",
    equality="array-contains",
    :value="value"
    ).pa-5
      template(v-slot:content="{ contents }")
        v-row.text-center.mx-auto
          v-col(v-for="book in contents", :key="book.id", md="3", lg="2", sm="4" cols="6 ")
            tooltipBtn
              template(v-slot:tooltip-content)
                div {{ book.title }}
                div.caption.font-weight-light
                  span(v-if="book.author.length == 1") by {{ book.author[0] }}
                  span(v-else) by {{ book.author[0] }} <span v-for="item in (book.author.slice(1,book.author.length-1))">, &nbsp {{ item }} &nbsp </span> & {{ book.author[book.author.length - 1] }}
                div.caption.font-weight-light {{book.publisher}} • {{book.year}}
              template(v-slot:content)
                v-card.mx-auto.justify-content-center.align-center(height="235px", width="150px", elevation="0", tile  @click="link(book.id)")
                  bookCover(:url="book.imageUrl" imgHeight="160" imgWidth="110").mx-auto
                  v-list-item-group.mx-auto
                    v-list-item-content
                      v-list-item-title.body-1 
                        strong {{ book.title }}
                      v-list-item-title.mt-n1.body-2.text--secondary by &nbsp
                        span(v-if="book.author.length == 1") {{ book.author[0] }}
                        span(v-else) {{ book.author[0] }} <span v-for="item in (book.author.slice(1,book.author.length-1))">, &nbsp {{ item }} &nbsp </span> & {{ book.author[book.author.length - 1] }}

</template>

<script>
import subjects from "@/subjects.js";
import bookCover from "../components/BookCover.vue";
import paginate from "../components/Paginate.vue";
import tooltipBtn from "../components/TooltipBtn.vue";
export default {
  components: { bookCover, paginate, tooltipBtn },
  data() {
    return {
      limit: 12,
      value: this.$route.params.slug,
    };
  },

  computed: {
    subject() {
      return subjects.subjects.find((subject) => subject.name === this.value);
    },
  },

  methods: {
    link: function (id) {
      this.$router.push({ name: "Book-item", params: { id: id } });
    },
  },
};
</script>
